<template>
  <div class="container">
    <img class="poster" :src="posterUrl" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      posterUrl: ''
    }
  },
  computed: {
    code() {
      return this.$route.query.dcode;
    }
  },
  created() {
    this.getPoster();
  },
  methods: {
    getPoster() {
      this.$axios.get('/Api/api/Web/Article/GetImg?sCode=' + this.code).then(res => {
        this.posterUrl = res.Data.File_Url;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  .poster {
    display: block;
    width: 100%;
  }
}
</style>